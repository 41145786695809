import moment from "moment";
import React, { Component } from "react";
import DropdownSelect from "../../components/dropdown/Dropdown";
import DropdownAction from "../../components/dropdown/DropdownAction";
import Table from "../../components/table/Table";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { isNullString } from "../../utils/Utils";
import { saveAs } from "file-saver";
import { CommonValidationMessages } from "../../utils/Messages";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import ValidationText from "../../utils/validation/ValidationText";
import { validate } from "../../utils/validation/CommonValidator";
export default class ExtractInvoices extends Component {
    constructor(props) {
        super(props);
        this.financeService = new FinanceServices();
        this.swalServices = new SwalServices();
        const today = moment();
        this.state = {
            totalResultes: 0,
            extractInvoicesList: [],
            zipInvoicesList: [],
            // extractInvoiceDetails: {
            //     dateFrom: "",
            //     dateTo: "",
            // },

            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                invoiceType: 0,
                dateFrom: '',
                dateTo: '',
                filterbyInvoiceNumber: ''
            },
            typeList: [
                {
                    id: 1,
                    name: "Event",
                },
                {
                    id: 2,
                    name: "Membership",
                },
                {
                    id: 3,
                    name: "External Invoice",
                },
            ],
            invoiceTypeId: 0,
            value: moment.range(today.clone().subtract(7, "days"), today.clone()),
            isOpen: false,
            actionDropdownPaid: ["View Invoice"],
            actionDropdownPaid2: ["View Invoice", "View Receipt"],
            isCreatingZip: false,
            isLoading: false,
            validState: {
                isValid: true,
                error: {},
            },
            validationRule: {
                dateFrom: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace(
                            "{0}",
                            "start date"
                        ),
                    },
                    {
                        type: "isMinDate",
                        message: "Please enter valid date",
                    },
                ],
                dateTo: [
                    {
                        type: "require",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "end date"
                        ),
                    },
                    {
                        type: "isMinDate",
                        message: "Please enter valid date",
                    },
                ],
            }

        };
    }



    // onToggle = () => {
    //     if (this.state.pagination.invoiceType > 0) {
    //         this.setState({ isOpen: true });
    //     }
    // };

    // onSelect = (value, states) => {
    //     this.setState({ value, states });
    //     let startDate;
    //     let endDate;
    //     startDate = value.start.format("YYYY/MM/DD HH:MM:SS");
    //     endDate = value.end.format("YYYY/MM/DD HH:MM:SS");
    //     let pageObj = { ...this.state.pagination };
    //     pageObj.dateFrom = startDate;
    //     pageObj.dateTo = endDate;

    //     this.setState({ pagination: pageObj }, () => {
    //         this.getAllExtractInvoicesList(false);
    //     });
    //     this.setState({ isOpen: !this.state.isOpen });
    // };

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getAllExtractInvoicesList(false);
    };

    handleChangeInDropdown = (id, drpIdentity) => {
        let detail = this.state.pagination;
        if (drpIdentity === "InvoiceTypes") {
            detail.invoiceType = id
            this.setState({ pagination: detail })

            //this.setState({ invoiceType: id })
            if (this.state.pagination.invoiceType !== 0) {
                //this.getAllExtractInvoicesList()
            } else {
                this.setState({ extractInvoicesList: [] });
            }
        }
    }

    actionClick = (index, value, option, event) => {
        let path = "";
        let name = "";
        if (value && value > 0) {
            if (option === "View Invoice") {

                path = this.state.extractInvoicesList[index].invoicePath;
                name = this.state.extractInvoicesList[index].invoiceNo;
                this.setState({ name: name })
            } else if (option === "View Receipt") {
                path = this.state.extractInvoicesList[index].receiptPath;
                name = this.state.extractInvoicesList[index].receiptNo;
                this.setState({ name: name })
            }
            if (isNullString(path) !== '') {
                this.financeGetCreditNoteInvoice(path, option);
            }
        }
    };
    financeGetCreditNoteInvoice = (value, option) => {
        let request = [];
        request.push(value);
        if (isNullString(value) !== "") {
            this.financeService
                .financeGetCreditNoteInvoice(request)
                .then((response) => {
                    if (response.data !== null && response.status === 200) {
                        let filename = "";
                        // if (option === "View Invoice") {
                            filename =this.state.name;
                        // } else {
                            // filename = this.state.name;
                        // }
                        let file = new Blob([response.data], { type: "application/pdf" });

                        URL.createObjectURL(file);
                        window.open(URL.createObjectURL(file), "_blank");

                        saveAs(file, filename + ".pdf");
                    } else {
                        this.swalServices.Error(response.message);
                    }
                });
        } else {
            this.swalServices.Error("No Invoice is Available");
        }
    };

    getAllExtractInvoicesList = (isDownloadZip = false) => {
        if (isDownloadZip === false) {
            let page = this.state.pagination;
            page.pageSize = 10;
            this.setState({ pagination: page })
        }
        if (this.state.pagination.invoiceType > 0) {
            this.setState({ isLoading: true })
            this.financeService.GetAllExtractInvoicesList(this.state.pagination).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    let data = response.responseItem.responseContent.itemList;
                    data.map((x) => {
                        { x.invoiceDate ? x.invoiceDate = moment(x.invoiceDate).format("DD MMM YYYY") : x.invoiceDate = "" }
                        { x.invoiceStatus ? x.invoiceStatus = x.invoiceStatus : x.invoiceStatus = "" }
                    });
                    this.setState({ invoiceTypeId: this.state.pagination.invoiceType });
                    this.setState({ extractInvoicesList: data, totalResultes: response.responseItem.responseContent.totalCount }, () => {

                        this.setState({ isLoading: false });
                        if (isDownloadZip === true) {
                            this.zipInvoices();
                        }
                    });
                    // this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
                } else {
                    this.swalServices.Error("Something went wrong.");
                    this.setState({ isLoading: false });
                }
            })
        } else {
            this.swalServices.Alert("Please select invoice type.");
        }
    }

    zipInvoices = () => {
        let pagination = { ...this.state.pagination };
        //let pagination = this.state.pagination;

        if (pagination.invoiceType > 0) {
            // if(isDow)
            // {
            //     this.getAllExtractInvoicesList();
            // }
            let req = {};
            req.extractInvoicesList = this.state.extractInvoicesList;
            req.pagination = pagination;
            req.pagination.pageSize = this.state.totalResultes
            // req.pagination.pageSize = this.state.pagination.pageSize;
            req.pagination.pageNo = 1;

            if (req.extractInvoicesList.length > 0) {
                this.setState({ isCreatingZip: true });
                this.financeService.zipInvoices(req).then((response1) => {
                    if (response1.statusCode === 200 && response1.responseItem != null) {
                        if (isNullString(response1.responseItem.responseContent) !== "") {
                            let path = response1.responseItem.responseContent
                            console.log("response1.responseItem.responseContent", response1.responseItem.responseContent);
                            let request = [path];
                            this.financeService.financeGetInvoice(request).then((response) => {
                                console.log("Response 1", response);
                                if (response !== null) {
                                    let formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")
                                    // let InvoiceSplit = response1.responseItem.responseContent;
                                    // const name = InvoiceSplit.split("/");
                                    let detail = ""
                                    if (req.extractInvoicesList[0].invoiceType === "Event") {
                                        detail = "Event";
                                    }
                                    if (req.extractInvoicesList[0].invoiceType === "Membership") {
                                        detail = "MembershipInvoice";
                                    }
                                    if (req.extractInvoicesList[0].invoiceType === "External Invoice") {
                                        detail = "ExternalInvoice";
                                    }
                                    let filename = `${detail}${formattedDate}`;

                                    let blob = new Blob([response.data])
                                    saveAs(blob, filename + ".zip");
                                    this.setState({ isCreatingZip: false });
                                } else {
                                    this.swalServices.Error(response.message);
                                }
                            });
                        }
                    } else {
                        this.swalServices.Error("Something went wrong.");
                        this.setState({ isCreatingZip: false });
                    }
                })
            }
            else {
                this.swalServices.Alert("No records found.");
                this.setState({ isCreatingZip: false });
            }
        }
        else {
            this.swalServices.Alert("Please select invoice type.");
        }
    }

    actions = (element, index, value) => {
        return element !== "Id" ? null : (
            <td>
                <div className="relative w-full credit-note-action-dropdown">
                    <ActionContainer>

                        <div className="mr-8">
                            {/* <DropdownAction
                            key={index}
                            value={value}
                            id={index}
                            index={index}
                            options={this.state.actionDropdownPaid}
                            align="right"
                            onOptionClick={this.actionClick.bind(this)}
                        /> */}
                            {(this.state.extractInvoicesList.find(x => x.id === value)).invoiceStatus === 'Booked' ||
                                (this.state.extractInvoicesList.find(x => x.id === value)).invoiceStatus === 'Success'
                                ?
                                <DropdownAction
                                    key={index}
                                    value={value}
                                    id={index}
                                    index={index}
                                    options={this.state.actionDropdownPaid2}
                                    align="right"
                                    onOptionClick={this.actionClick.bind(this)}
                                />
                                :

                                <DropdownAction
                                    key={index}
                                    value={value}
                                    id={index}
                                    index={index}
                                    options={this.state.actionDropdownPaid}
                                    align="right"
                                    onOptionClick={this.actionClick.bind(this)}
                                />
                            }

                        </div>
                    </ActionContainer>
                </div>
            </td>
        );
    };

    componentDidMount() {
        // this.getAllExtractInvoicesList();

        // document.addEventListener('contextmenu', (e) => {
        //     e.preventDefault();
        //   });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({ pagination: detail });
        //this.getAllExtractInvoicesList();
    };


    search = () => {
        let detail = this.state.pagination;
        detail.pageNo = 1;
        this.setState({ pagination: detail }, () => { this.getAllExtractInvoicesList() })
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.pagination,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };


    render() {
        return (
            <div className="main-body-section finance-body-sec">
                <div className="bg-white pb-2 mt-10">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-12">
                        <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
                            <h2 className="text-xl theme-color mb-2 font-bold">
                                Invoice Number
                                <span className="text-[#AA3361]">*</span>
                            </h2>
                            <input
                                id="default"
                                name="filterbyInvoiceNumber"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="text"
                                onChange={(event) => this.handleChange(event)}
                                value={this.state.pagination.filterbyInvoiceNumber}
                            />
                        </div>

                        <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
                            <h2 className="text-xl theme-color mb-2 font-bold"> Invoice Type
                                <span className="text-[#AA3361]">*</span>
                            </h2>
                            <DropdownSelect
                                drpIdentity="InvoiceTypes"
                                optionArray={this.state.typeList}
                                setFilterParameters={this.handleChangeInDropdown.bind(this)}
                                value={this.state.pagination.invoiceType}
                            />
                        </div>
                        <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                            <h2 className="text-xl theme-color mb-2 font-bold">Invoice Date Range</h2>
                            <div className="grid grid-cols-12 gap-6 items-center w-full">
                                <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="date"
                                        min="1900-01-01"
                                        max="9999-12-31"
                                        value={moment(
                                            this.state.pagination.dateFrom
                                        ).format("yyyy-MM-DD")}
                                        onChange={(e) => this.handleChange(e)}
                                        onBlur={() =>
                                            this.validateField("dateFrom")
                                        }
                                    />
                                    <div>
                                        <ValidationText
                                            error={
                                                this.state.validState.error
                                                    .dateFrom
                                            }
                                        />
                                    </div>
                                </div>
                                <div className=" 2xl:col-span-1 lg:col-span-1 col-span-12 w-full text-center">
                                    <h2 className="text-xl theme-color mb-1 font-medium">To</h2>
                                </div>
                                <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                                    <input
                                        id="default"
                                        name="dateTo"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="date"
                                        min="1900-01-01"
                                        max="9999-12-31"
                                        value={moment(
                                            this.state.pagination.dateTo
                                        ).format("yyyy-MM-DD")}
                                        onChange={(e) => this.handleChange(e)}
                                        onBlur={() =>
                                            this.validateField("dateTo")
                                        }
                                    />
                                    <div>
                                        <ValidationText
                                            error={
                                                this.state.validState.error
                                                    .dateTo
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className={`right-date-range ${this.state.pagination.invoiceType > 0 ? "" : "range-disabled"}`}>
                                <button className="w-full h-[58px] border-solid border-[#181818] btn  py-3 text-lg text-[#181818] flex items-left date-rang-sec relative">
                                    <div className="right-date-range">
                                        <div className="h-[45px] inline-block pt-2 w-full">
                                            <DateRangePickkerSelect
                                                pagination={this.state.pagination}
                                                value={this.state.value}
                                                onSelect={this.onSelect.bind(this)}
                                                isOpen={this.state.isOpen}
                                                onToggle={this.onToggle.bind(this)}
                                                changeIsOpenFalse={this.changeIsOpenFalse.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </button>
                            </div> */}
                        </div>
                        <div className="2xl:col-span-12 pt-10 lg:col-span-12 col-span-12 w-full">
                            <div className=" flex items-center justify-end w-full space-x-4">
                                <button className="btn btn-pink text-xl border text-white "
                                    onClick={() => this.search()}
                                >
                                    Search
                                </button>
                                {this.state.isCreatingZip === true ?
                                    <ButtonLoader /> :
                                    <button className="btn btn-pink text-xl border text-white "
                                        onClick={() => this.getAllExtractInvoicesList(true)
                                        }
                                    >
                                        Create Zip
                                    </button>
                                }


                            </div>
                        </div>


                    </div>

                </div>

                {/* Table Section Start */}
                <div className="mt-10 external-invoice-tbl">
                    {this.state.invoiceTypeId === 1 ?
                        <>
                            <Table
                                columns={[
                                    { name: "InvoiceNo", title: "Invoice Number" },
                                    { name: "bookingReferenceNo", title: "Booking Reference Number" },
                                    { name: "invoiceDate", title: "Invoice Date" },
                                    { name: "InvoiceStatus", title: "Invoice Status" },
                                    { name: "InvoiceType", title: "InvoiceType" },
                                    { name: "Id", title: "Actions" },
                                ]}
                                rows={this.state.extractInvoicesList}
                                isLoading={this.state.isLoading}
                                sortingColumns={["", ""]}
                                pagination={this.state.pagination}
                                totalResultes={this.state.totalResultes}
                                totalCount={this.state.totalResultes}
                                setPagination={this.setPagination.bind(this)}
                                customScope={[
                                    {
                                        column: "Id",
                                        renderTableData: this.actions.bind(this),
                                    },
                                    // {
                                    //     column: "selectRow",
                                    //     renderTableData: this.select.bind(this),
                                    //     valueColumnName: "financeAdhocInvoiceId",
                                    // },
                                ]}
                            />
                        </>
                        :
                        <>
                            <Table
                                columns={[
                                    { name: "InvoiceNo", title: "Invoice Number" },
                                    { name: "invoiceDate", title: "Invoice Date" },
                                    { name: "InvoiceStatus", title: "Invoice Status" },
                                    { name: "InvoiceType", title: "InvoiceType" },
                                    { name: "Id", title: "Actions" },
                                ]}
                                rows={this.state.extractInvoicesList}
                                isLoading={this.state.isLoading}
                                sortingColumns={["", ""]}
                                pagination={this.state.pagination}
                                totalResultes={this.state.totalResultes}
                                totalCount={this.state.totalResultes}
                                setPagination={this.setPagination.bind(this)}
                                customScope={[
                                    {
                                        column: "Id",
                                        renderTableData: this.actions.bind(this),
                                    },
                                    // {
                                    //     column: "selectRow",
                                    //     renderTableData: this.select.bind(this),
                                    //     valueColumnName: "financeAdhocInvoiceId",
                                    // },
                                ]}
                            />
                        </>
                    }
                </div>
                {/*Table Section End */}

            </div>


        );
    }
}

