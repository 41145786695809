import React, { Component } from "react";
import Table from "../../../components/table/Table";
import { Navigate } from "react-router-dom";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import IndividualMemberService from "../../../services/axiosServices/apiServices/IndividualMemberService";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import DropdownAction from "../../../components/dropdown/DropdownAction";
import MembershipCorporateServices from "../../../services/axiosServices/apiServices/MembershipCorporateServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import { CommonSuccessMessages } from "../../../utils/Messages";
import { encryptAES } from "../../../utils/Encryption";
import moment from "moment";

export default class ViewIndividualMembers extends Component {
  constructor(props) {
    super(props);
    this.individualMemberService = new IndividualMemberService();
    this.membershipCorporateServices = new MembershipCorporateServices();
    this.swalServices = new SwalServices();
    this.state = {
      setDropdownOpen: false,
      setDropdownIndex: 0,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        searchByEmailAddress: "",
        IndividualStatusId: 0,
        MembershipTypeId: 0,
      },
      totalResultes: "",
      individualMemberList: [],
      actionDropdown: ["Activate", "Deactivate", "Update", "Delete"],

      isLoading: false,
      redirect: null,
      searchByName: "",
      searchByEmailAddress: "",
      statuses: [],
      membershipTypes: [],
    };
    this.wrapperRef = React.createRef();
  }

  // setFilterParameters = (id) => {
  //   let detail = this.state.pagination;
  //   detail.pageNo = 1;
  //   detail.IndividualStatusId = id;

  //   this.setState({ pagination: { ...detail } });
  //   this.getIndividualMemberList();
  // };
  setFilterParameters = (id, type) => {
    let detail = this.state.pagination;
    detail.pageNo = 1;

    if (type === "status") {
      detail.IndividualStatusId = id;
    } else if (type === "membershipType") {
      detail.MembershipTypeId = id;
    }

    this.setState({ pagination: { ...detail } });
    this.getIndividualMemberList();
  };

  getIndividualMemberList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.individualMemberService
      .getIndividualMemberList(pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent.itemList;
          details.map((key, index) => {
            key.subscriptionEndDate = key.subscriptionEndDate ? moment(key.subscriptionEndDate).format("DD MMM YYYY") : "";
          })
          this.setState({
            individualMemberList: response.responseItem.responseContent.itemList,
            totalResultes: response.responseItem.responseContent.totalCount,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getIndividualMemberList();
  };

  componentDidMount() {
    this.getAllCorporateStatus();
    this.getAllDropdownsForCorporateMember();
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  redirectToCreateResources = (value) => {
    this.setState({ route: "/EditResource/ResourceId?=" + value });
  };

  redirectToApproveResources = (value) => {
    this.setState({ route: "/PostApproval/resourceId?=" + value });
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ setDropdownOpen: false });
    }
  }

  onDropDownChange = (index) => {
    this.setState({ setDropdownOpen: true });
    this.setState({ setDropdownIndex: index });
  };

  onClose = () => {
    this.setState({ setDropdownOpen: false });
  };

  getAllCorporateStatus = () => {
    this.membershipCorporateServices
      .getAllCorporateStatus()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState(
            { statuses: response.responseItem.responseContent },
            () => this.getIndividualMemberList()
          );
        } else {
          this.getIndividualMemberList();
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };
  getAllDropdownsForCorporateMember = () => {
    this.membershipCorporateServices
      .getAllDropdownForCorporateMember()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          // Membership Types
          let membershipTypes = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "MembershipTypes"
          );
          membershipTypes.map(function (item) {
            return delete item["dropdownName"];
          });
          membershipTypes = membershipTypes.map((p) => ({
            id: p.id,
            name: p.name,
          }));

          const idToRemove = 3;
          let filteredPeople = membershipTypes.filter((item) => item.id !== idToRemove);
          membershipTypes = filteredPeople
          this.setState(
            {
              membershipTypes: membershipTypes,
            },
            () => this.getIndividualMemberList()
          );
        }
      });
  };

  actionClick = (index, value, option) => {
    if (value && value > 0 && option === "Deactivate") {
      this.deActiveIndividualMember(value);
    }
    if (value && value > 0 && option === "Activate") {
      this.activeInActiveIndividualMember(value);
    }
    if (value && value > 0 && option === "Update") {
      this.updateIndividualMember(value, index);
    }
    if (value && value > 0 && option === "Delete") {
      this.deleteIndividualMember(value);
    }
  };

  deActiveIndividualMember = (value) => {
    let request = {
      id: value,
      statusId: 7,
    };
    this.individualMemberService
      .activeInActiveIndividualMember(request)
      .then((response) => {
        if (response.statusCode === 200) {
          this.swalServices.Success(
            "Individual member deactivated successfully."
          );
          this.getIndividualMemberList();
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  activeInActiveIndividualMember = (value) => {
    let request = {
      id: value,
      statusId: 8,
    };
    this.individualMemberService
      .activeInActiveIndividualMember(request)
      .then((response) => {
        if (response.statusCode === 200) {
          this.swalServices.Success(
            "Individual member activated successfully."
          );
          this.getIndividualMemberList();
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  updateIndividualMember = (value, index) => {
    let memberId = this.state.individualMemberList[index].membershipTypeId;
    if (value && value > 0) {
      this.setState({
        route:
          "/CreateIndividualMembers/individualMemberId?=" +
          encryptAES(value) +
          encryptAES(memberId),
      });
    }
  };

  deleteIndividualMember = (value) => {
    let request = {
      id: value,
      deletedBy: 115,
      appId: 15,
    };
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Question.replace("{0}", "Member"),
        CommonSuccessMessages.Confirm_Delete.replace("{0}", "Member"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.individualMemberService
            .deleteIndividualMember(request)
            .then((response) => {
              if (response.statusCode === 200) {
                this.swalServices.Success(
                  "Individual member deleted successfully."
                );
                this.getIndividualMemberList();
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            });
        }
      });
  };

  searchByName = (value, identity) => {
    let detail = this.state.pagination;
    if (identity === "searchByName") {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    } else if (identity === "searchByEmailAddress") {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchByEmailAddress = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getIndividualMemberList();
  };

  actions = (element, index, value) => {
    return element !== "individualMemberId" ? null : (
      <td>
        <ActionContainer>
          <DropdownAction
            key={index}
            index={index}
            value={value}
            options={this.state.actionDropdown}
            align="right"
            onOptionClick={this.actionClick.bind(this)}
          />
        </ActionContainer>
      </td>
    );
  };

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="custom-card shadow-lg mb-10">
            <div className="grid grid-cols-4 gap-6 w-full  ">
              <div className="">
                <h2 className="text-xl text-[#181818] mb-2">Search by Name</h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) =>
                    this.searchByName(e.target.value, "searchByName")
                  }
                />
              </div>
              <div className=" ">
                <h2 className="text-xl text-[#181818] mb-2">
                  Search by Email Address
                </h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                  onChange={(e) =>
                    this.searchByName(e.target.value, "searchByEmailAddress")
                  }
                />
              </div>
              <div className="">
                <h2 className="pl-6  text-xl text-[#181818] mb-2">Status</h2>
                <div className="pl-6  border-l-[1px] border-[#181818]">
                  <DropdownSelect
                    //drpIdentity={"MembershipType"}
                    optionArray={this.state.statuses}
                    setFilterParameters={(id) =>
                      this.setFilterParameters(id, "status")
                    }
                    value={this.state.pagination.IndividualStatusId}
                  />
                </div>
              </div>
              <div className="">
                <h2 className="text-xl text-[#181818] mb-2">
                  Membership Type
                </h2>

                <DropdownSelect
                  optionArray={this.state.membershipTypes}
                  setFilterParameters={(id) =>
                    this.setFilterParameters(id, "membershipType")
                  }
                  value={this.state.pagination.MembershipTypeId}
                />

              </div>
            </div>
          </div>

          <div className="sm:flex sm:justify-between sm:items-center mb-8 mt-8">
            <div className="mb-4 sm:mb-0">
              <h1 className="table-title-txt theme-color font-bold">
                Total : {this.state.totalResultes}
              </h1>
            </div>
            {/* <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
              <CreateButton
                redirect="/Membership/CreateIndividualMembers"
                text="Create New"
              />
            </div> */}
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div>
                  <div className=" mt-6" onMouseLeave={() => this.onClose()}>
                    <Table
                      columns={[
                        { name: "name", title: "Name" },
                        { name: "email", title: "Email Address" },
                        { name: "subscriptionEndDate", title: "Subscription End Date" },
                        { name: "statusName", title: "Status" },
                        {
                          name: "membershipTypeId",
                          title: "Membership Type",
                          render: (rowData) =>
                            rowData.membershipTypeId !== null
                              ? rowData.membershipTypeId
                              : "Nullable",
                        },
                        { name: "individualMemberId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.individualMemberList}
                      sortingColumns={[
                        "name",
                        "statusName",
                        "membershipTypeId",
                        "subscriptionEndDate",
                      ]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "individualMemberId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}
